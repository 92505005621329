import { useLayoutEffect, useRef } from 'react';
export var SingleItemContainer = function SingleItemContainer(_ref) {
  var initValue = _ref.initValue,
      useHookBody = _ref.useHookBody,
      applyStateChange = _ref.applyStateChange;
  var lastState = useRef(initValue);

  if (typeof useHookBody !== 'function') {
    throw new Error("function expected as hook body parameter. got " + typeof useHookBody);
  }

  var val = useHookBody(); //useLayoutEffect is safe from SSR perspective because SingleItemContainer should never be rendered on server

  useLayoutEffect(function () {
    if (lastState.current !== val) {
      lastState.current = val;
      applyStateChange(val);
    }
  }, [applyStateChange, val]);
  return null;
};