import { useEffect, useState } from 'react';
import { addHook } from './components/SingletonHooksContainer';
import { batch } from './utils/env';
export var singletonHook = function singletonHook(initValue, useHookBody) {
  var mounted = false;
  var initStateCalculated = false;
  var lastKnownState = undefined;
  var consumers = [];

  var applyStateChange = function applyStateChange(newState) {
    lastKnownState = newState;
    batch(function () {
      return consumers.forEach(function (c) {
        return c(newState);
      });
    });
  };

  var stateInitializer = function stateInitializer() {
    if (!initStateCalculated) {
      lastKnownState = typeof initValue === 'function' ? initValue() : initValue;
      initStateCalculated = true;
    }

    return lastKnownState;
  };

  return function () {
    var _useState = useState(stateInitializer),
        state = _useState[0],
        setState = _useState[1];

    useEffect(function () {
      if (!mounted) {
        mounted = true;
        addHook({
          initValue: initValue,
          useHookBody: useHookBody,
          applyStateChange: applyStateChange
        });
      }

      consumers.push(setState);

      if (lastKnownState !== state) {
        setState(lastKnownState);
      }

      return function () {
        consumers.splice(consumers.indexOf(setState), 1);
      }; // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return state;
  };
};